import "./AddToHome.css";

export default function AddToHome() {
  return (
    <div id="add_to_home-container">
      <div className="content-column">
        <h1>Add StageDive to my Home Screen</h1>

        <h2>Mobile Installation</h2>
        <p>
          While we are working diligently to make StageDive a fully-featured and
          rich streaming experience, we currently do not have a mobile app
          available for download.
          <br />
          <br />
          However, we want to help make StageDive more accessible in the
          meantime.
          <br />
          <br />
          Use the below reference for help creating a shortcut to the web
          version of StageDive on your mobile device.
        </p>

        <div className="view-card">
          <div className="view-select">
            <button onClick={iosView}>For iOS</button>
            <button onClick={androidView}>For Android</button>
          </div>

          <section id="ios">
            <h2>iOS Device</h2>

            <ol>
              <li>
                Visit <b>StageDive.com</b> using the <b>Safari</b> browser.
              </li>
              <li>
                Tap the{" "}
                <b>
                  <span className="material-symbols-rounded">ios_share</span>{" "}
                  icon
                </b>{" "}
                at the bottom-center of your screen.
              </li>
              <li>
                Select the{" "}
                <b>
                  'Add to Home Screen{" "}
                  <span className="material-symbols-rounded">add_box</span>'
                </b>{" "}
                option (you may need to scroll).
                <br />
                <br />
                **If you don't see the <b>'Add to Home Screen'</b> option, you
                may need to enable it. Scroll all the way down to{" "}
                <b>'Edit Actions'</b> to do this.
              </li>
              <li>
                You may name the app whatever you want to see on your home
                screen. Select <b>'Add'</b> at the top-right corner to save your
                shortcut!
              </li>
            </ol>
          </section>

          <section id="android">
            <h2>Android Device</h2>

            <ol>
              <li>
                Visit <b>StageDive.com</b> using the <b>Google Chrome</b>{" "}
                browser.
              </li>
              <li>
                Tap the{" "}
                <b>
                  <span className="material-symbols-rounded">more_vert</span>{" "}
                  icon
                </b>{" "}
                at the top-right of your screen.
              </li>
              <li>
                Select the <b>'Add to Home Screen'</b> option (you may need to
                scroll).
              </li>
              <li>
                Select <b>'Add'</b> in the popup to save your shortcut!
              </li>
            </ol>
          </section>
        </div>

        <section>
          <h2>More Help</h2>
          <p>
            If these instructions did not have the information you needed,
            please reach out so we can assist you and improve our platform.
            <br />
            <br />
            You may direct message us on Twitter/X at{" "}
            <a
              href="https://twitter.com/getstagedive"
              target="_blank"
              style={{ textDecoration: "underline" }}
              rel="noreferrer"
            >
              StageDive - X
            </a>
            .
            <br />
            <br />
            For bug reports and feature requests, please use the Bug Reporting{" "}
            <span className="material-symbols-rounded bug-report-icon">
              bug_report
            </span>{" "}
            feature.
          </p>
        </section>
      </div>
    </div>
  );
}

function iosView() {
  $("#android").hide();
  $("#ios").fadeIn();
}

function androidView() {
  $("#ios").hide();
  $("#android").fadeIn();
}
