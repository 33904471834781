import { useCallback, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../../../context";

export const CreatorRequests = () => {
  const [creatorRequests, setCreatorRequests] = useState([] as any);
  const stagedive = useContext(ApiContext);

  const getCreatorRequests = useCallback(async () => {
    try {
      const creatorRequests = await stagedive.listCreatorRequests();
      setCreatorRequests(creatorRequests);
    } catch (err) {
      console.error("Could not get creator requests");
    }
  }, [stagedive]);

  const changeRequestStatus = async (creatorRequest, status) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to set this request to ${status}?`
        )
      ) {
        await stagedive.updateCreatorRequest(creatorRequest.id, {
          ...creatorRequest,
          status,
        });
        if (status === "approved") {
          alert("Success, Creator privileges added to user");
        }
        if (status === "rejected") {
          alert("Request has been rejected");
        }
        await getCreatorRequests();
      }
    } catch (error) {
      alert(`Error updating request: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    getCreatorRequests();
  }, [getCreatorRequests]);

  return (
    <>
      <h3>Creator Requests</h3>
      <div className="bg-neutral-900 rounded-md mb-6 p-2 overflow-x-auto">
        <table cellSpacing="0" cellPadding="0" className="table border-none">
          <thead>
            <tr>
              <th>Id</th>
              <th>Details</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {creatorRequests.map((cr) => (
              <tr className="hover:bg-neutral-800">
                <td>
                  <p>{cr.id}</p>
                </td>
                <td>
                  <p>{cr.details.creatorName}</p>
                  <p>{cr.details.message}</p>
                  <p>{cr.details.feedback}</p>
                  <p>{cr.details.contactEmail}</p>
                </td>
                <td>
                  <p className="capitalize">{cr.status}</p>
                </td>
                <td>
                  <div className="flex gap-3">
                    {cr.status === "pending" && (
                      <>
                        <button
                          className="btn tooltip capitalize"
                          data-tip="Approve Request"
                          onClick={() => changeRequestStatus(cr, "approved")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="btn tooltip capitalize"
                          data-tip="Reject Request"
                          onClick={() => changeRequestStatus(cr, "rejected")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
