import { create } from "zustand";

interface UserState {
  user: CurrentUser | null;
  setUser: (user: CurrentUser | null) => void;
}

const useUserStore = create<UserState>()((set) => ({
  user: null,
  setUser: (user) => set({ user }),
}));

export { useUserStore };
