import Modal from "../../../../../../components/Modal";

type Props = {
  album;
  handleSubmit;
  open;
  handleToggle;
  isLoading;
  error;
};

const UpdateAlbumModal = ({
  album,
  handleSubmit,
  open,
  handleToggle,
  isLoading,
  error,
}: Props) => {
  return (
    <Modal open={open} onClose={handleToggle}>
      <h3 className="font-bold text-lg">Update Album</h3>
      <form className="w-full" onSubmit={(e) => handleSubmit(e, album)}>
        <div>
          <label className="mb-4 pb-4 font-bold" htmlFor="name">
            Name
          </label>
          <input
            className="bg-black shadow appearance-none border rounded w-full mt-2 p-3 text-white focus:outline-none focus:shadow-outline"
            placeholder="My Album"
            name="name"
            type="text"
            defaultValue={album.name}
          />
        </div>

        <div className="mt-3">
          <label htmlFor="imageFile">Album Image</label>
          <br />
          <small>Size limit: 5mb</small>
          <input
            className="block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-yellow-50 file:text-yellow-700
              hover:file:bg-yellow-100 mt-3"
            type="file"
            name="imageFile"
          />
        </div>

        <div className="modal-action">
          <button
            className="btn normal-case transition duration-300 ease-in-out hover:bg-neutral-700"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
      {error && (
        <div className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error}</span>
        </div>
      )}
    </Modal>
  );
};

export default UpdateAlbumModal;
