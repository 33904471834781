import * as Sentry from "@sentry/react";
import React, { useContext, useState } from "react";
import { toast } from "sonner";
import { Toaster } from "../primitives/Toast";

const ErrorContext = React.createContext({
  error: null,
  handleError: (error) => {},
});

export const useErrorHandler = () => {
  return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = async (error) => {
    setError(error);
    toast.error(`Error\n${error.message}`, {
      duration: 7000,
      cancel: {
        label: "Close",
        onClick: () => {},
      },
    });

    console.error("Error occurred:", error);
    if (process.env.REACT_APP_NODE_ENV === "production") {
      Sentry.captureException(error);
    }
  };

  return (
    <ErrorContext.Provider value={{ error, handleError }}>
      <Toaster></Toaster>
      {children}
    </ErrorContext.Provider>
  );
};
