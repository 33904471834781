import * as Sentry from "@sentry/browser";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserStore } from "store";
import SignOut from "../../../../components/SignOut";
import { ApiContext } from "../../../../context";
import { Button, Separator } from "../../../../primitives";
import "../../Profile.css";
import RequestAccountDialog from "./RequestAccountDialog";

export default function ProfileInfo() {
  const stagedive = useContext(ApiContext);
  const { user } = useUserStore();
  const [requestingCreatorAccount, setRequestingCreatorAccount] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [creatorRequestStatus, setCreatorRequestStatus] = useState("");
  const [creatorRequestDetails, setCreatorRequestDetails] = useState({
    message: "",
    creatorName: "",
    feedback: "",
    contactEmail: "",
  });

  useEffect(() => {
    const getCreatorRequest = async () => {
      try {
        if (user) {
          const getCreatorResp = await stagedive.getUserCreatorRequests(
            user.id
          );
          if (getCreatorResp.length > 0) {
            setCreatorRequestStatus(getCreatorResp[0].status);
            setRequestingCreatorAccount(true); // Hide the button
          } else {
            setCreatorRequestStatus("No creator request found.");
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCreatorRequest();
  }, [user, stagedive]);

  //Checks to see if the Creator Request form is valid (fully filled out)
  const isFormValid = () => {
    return Object.values(creatorRequestDetails).every(
      (value) => value.trim() !== ""
    );
  };

  const isCreator = (user) => {
    if (!user.roles) return false;
    else return user.roles.includes("creator");
  };

  const handleRequestCreatorAccount = () => {
    setShowModal(true);
  };

  const handleSubmitCreatorRequest: React.FormEventHandler<
    HTMLFormElement
  > = async () => {
    try {
      if (user) {
        const creatorData = {
          details: { ...creatorRequestDetails },
          status: "pending",
          userId: user.id,
        };
        try {
          await stagedive.createCreatorRequest(creatorData);
        } catch (error) {
          alert("Creator Request failed, please try again later");
          alert("Creator Request failed, please try again later");
          Sentry.captureException(error, { extra: creatorData });
          console.error("Error requesting creator account:", error);
        }

        // Update state to show the modal and disable the button
        setRequestingCreatorAccount(true);
        setShowModal(false);
        setCreatorRequestStatus(
          "Thank you for requesting a creator account. Your request is being reviewed by our team."
        );
        console.log("Submitted creator request.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className="p-10">
      <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl">
        Account
      </h1>

      <div className="flex flex-col gap-4 mt-8">
        <div>
          Email: <p>{user?.email}</p>
        </div>

        {creatorRequestStatus && !isCreator(user) ? (
          <div className=" ">
            Creator Request Status: <p>{creatorRequestStatus}</p>
          </div>
        ) : null}
        <Separator />

        {!isCreator(user) && !requestingCreatorAccount ? (
          <RequestAccountDialog />
        ) : null}

        {isCreator(user) && requestingCreatorAccount ? (
          <Button
            className="  w-min"
            onClick={handleRequestCreatorAccount}
            variant={"ghost"}
            disabled={requestingCreatorAccount}
          >
            Request Creator Account
          </Button>
        ) : null}

        {/* {showModal && (
          <div className="modal-content">
            <span onClick={() => setShowModal(false)} className="close">
              &times;
            </span>
            <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
              Creator Request Form
            </h3>
            <form onSubmit={handleSubmitCreatorRequest}>
              <div className="form-field">
                <label>
                  Creator Name:
                  <input
                    type="text"
                    value={creatorRequestDetails.creatorName}
                    className="text-black"
                    onChange={(e) =>
                      setCreatorRequestDetails({
                        ...creatorRequestDetails,
                        creatorName: e.target.value,
                      })
                    }
                    required
                  />
                </label>
              </div>
              <div className="form-field">
                <label>
                  Please link your artist socials here (Instagram, YouTube, X,
                  etc.):
                  <textarea
                    id="message"
                    name="message"
                    className="text-black"
                    value={creatorRequestDetails.message}
                    onChange={(e) =>
                      setCreatorRequestDetails({
                        ...creatorRequestDetails,
                        message: e.target.value,
                      })
                    }
                    required
                  />
                </label>
              </div>
              <div className="form-field">
                <label>
                  Tell us about yourself:
                  <textarea
                    id="feedback"
                    name="feedback"
                    className="text-black"
                    value={creatorRequestDetails.feedback}
                    onChange={(e) =>
                      setCreatorRequestDetails({
                        ...creatorRequestDetails,
                        feedback: e.target.value,
                      })
                    }
                    required
                  />
                </label>
              </div>
              <div className="form-field">
                <label>
                  Contact Email:
                  <input
                    type="email"
                    className="text-black"
                    value={creatorRequestDetails.contactEmail}
                    onChange={(e) =>
                      setCreatorRequestDetails({
                        ...creatorRequestDetails,
                        contactEmail: e.target.value,
                      })
                    }
                    required
                  />
                </label>
              </div>
              <button
                type="submit"
                className={`submit-button ${isFormValid() ? "" : "disabled"}`}
                disabled={!isFormValid()}
              >
                Submit
              </button>
            </form>
          </div>
        )} */}

        {/* {isCreator(user) ? (
            <Link to="/creator-dashboard" className="button">
              Visit Creator Dashboard
            </Link>
          ) : null} */}

        <Link to="/auth/reset-password">
          <Button className=" w-min" variant={"ghost"}>
            Reset Password
          </Button>
        </Link>
        <SignOut />
      </div>
    </section>
  );
}
