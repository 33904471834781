import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Tweet } from "react-tweet";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import SocialProof from "../../components/SocialProof";
import "./Landing.css";

export default function Landing() {
  const session = useSessionContext();
  const navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (session.loading) return null;

  if (session.doesSessionExist) {
    navigate("/home");
    return null;
  }

  return (
    <div id="landing-page">
      <Slider id="landing-hero" {...sliderSettings}>
        <div className="hero-image slide-1">
          <h1 className="hero-text">
            Disrupting the Music Industry. <br /> Your content, your way.
          </h1>
        </div>
        <div className="hero-image slide-2">
          <h1 className="hero-text">Join the StageDive community today. </h1>
        </div>
      </Slider>

      <div
        id="landing-signin"
        className="container flex justify-center mt-10 mb-5"
      >
        <div className="rounded bg-slate-800 py-10 px-20">
          <p className="text-center text-2xl font-bold mb-2">Start Listening</p>
          <div className="flex justify-center gap-5 items-center">
            <Link to={`/auth/?redirectToPath=%2Fhome`} className="btn">
              Sign In
            </Link>
            <Link to={`/auth/?show=signup`} className="btn">
              Sign Up
            </Link>
          </div>

          <div className="mt-10 sm:mx-3 rounded">
            <p className="text-center font-bold mb-3">
              Want to be a creator? Enter below
            </p>
            <div className="flex flex-row justify-center gap-2">
              <Link
                to={`/auth/?redirectToPath=%2Fprofile%2Fcreator-dashboard`}
                className="btn btn-sm"
              >
                Sign In
              </Link>
              <Link
                to={`/auth/?show=signup&redirectToPath=%2Fprofile%2Fcreator-dashboard`}
                className="btn btn-sm"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl text-center mx-auto">
          <h2 className="text-3xl font-bold mb-8">At StageDive, you get:</h2>
        </div>
        <div className="container mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-base-200 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">A fair-pay platform</h3>
            <p className="text-white">
              50% of paid subscriptions go directly to the artists you love.
            </p>
          </div>
          <div className="bg-base-200 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">Ad-free streaming</h3>
            <p className="text-white">You're here for music, not ads.</p>
          </div>
          <div className="bg-base-200 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">
              A growing catalog of independent artists
            </h3>
            <p className="text-white">
              Discover music and support new artists that need it most.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="text-white pt-12 pb-24 px-4">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">
            Make an Impact. <br />
            Sign up now.
          </h2>
          <p className="text-lg mb-8">1 plan. 1 price. Simple.</p>
          <div className="card bg-base-200 w-96 mx-auto max-w-full">
            <div className="card-body">
              <h2 className="card-title text-blue-500">
                StageDive Subscription
              </h2>
              <p className="text-left">Only $3.99/month &#40;USD&#41;</p>
              <ul className="text-left">
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  Directly supports the artists you listen to
                </li>
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  Ad-free streaming
                </li>
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  High quality audio
                </li>
                <li className="flex py-2">
                  <span className="material-symbols-rounded py-0 pr-3">
                    check_circle
                  </span>
                  A growing library of independent artists
                </li>
              </ul>
              <div className="card-actions mx-auto">
                <Link
                  to={`/auth/?show=signup`}
                  className="btn btn-primary bg-blue-600 border-none"
                >
                  Get StageDive
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-gray-900 text-white py-12 px-4">
        <div className="container mx-auto text-center">
          <p className="text-lg">
            With love for all artists &#40;even your mom&#41;
            <span className="material-symbols-rounded py-0 align-bottom">
              favorite
            </span>
          </p>
        </div>
      </section>

      {/* Q&A Section */}
      <section className="text-white py-24 px-4">
        <div className="container mx-auto max-w-5xl">
          <h2 className="text-3xl font-bold mb-3 text-center">
            Frequently Asked Questions
          </h2>
          <p className="text-sm text-center pb-6">
            Don't see your question here? Find our general <br />
            FAQ and Support links at the bottom of the page.
          </p>
          <div className="collapse collapse-arrow rounded-none border-b-2 border-slate-500">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              How are artists paid?
            </div>
            <div className="collapse-content">
              <p>
                Every month, you pay $3.99 to StageDive.
                <br />
                <br />
                $1.99 goes to StageDive's operations and development.
                <br />
                <br />
                The remaining $2.00 is split between the artists that you
                listened to that month, relative to the time spent with each
                artist.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow rounded-none border-b-2 border-slate-500">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Can I be a creator on StageDive?
            </div>
            <div className="collapse-content">
              <p>
                Anyone can request to be a creator on StageDive.{" "}
                <Link
                  to={`/auth/?show=signup&redirectToPath=%2Fprofile%2Fcreator-dashboard`}
                  className="underline"
                >
                  Apply for an account here
                </Link>{" "}
                to start your journey.
              </p>
            </div>
          </div>
          <div className="collapse collapse-arrow rounded-none border-b-2 border-slate-500">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Is there a StageDive app for iOS or Android?
            </div>
            <div className="collapse-content">
              <p>
                While we are working diligently to make StageDive a
                fully-featured and rich streaming experience, we currently do
                not have a mobile app available for download. Stagedive can be
                streamed from any browser. You may use{" "}
                <Link to="/add-to-home" className="underline">
                  this reference
                </Link>{" "}
                to set up a shortcut on your mobile device.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="divider"></div>

      <div id="landing-as-seen-in" className="p-20">
        <h2 className="text-2xl text-center">As Seen On</h2>
        <div className="flex flex-col justify-between gap-10 mt-6 md:fl">
          <SocialProof
            name="EntreMusician"
            url="https://theentremusician.com/watch-this-now"
            imageUrl={require("../../assets/images/entremusic.png")}
            description="The EntreMusician Is A Space to Engage, Encourage and Empower Fellow EntreMusicians through Creative Collaboration Composer/Producer/Musician Jere B Blogs & Vlogs on Topics from P. Home page of The EntreMusician, a music-related company from Youngstown."
          />
          <SocialProof
            name="Our Mind On Music"
            url="https://youtu.be/Afnt5eybn5o?feature=shared"
            imageUrl={require("../../assets/images/ourmind.png")}
            description="In this episode, Jon Bruce from the #Stagedive #Streaming #Platform and I, explore how Stagedive is working with a subscription model to support creators and listeners in response to the current imbalance in ad models which seem to benefit distributors while giving very little direct benefit to creators. Is this a model for the future of music and commentary distribution? "
          />
          <SocialProof
            name="Fearless Formula"
            url="https://podcasts.apple.com/us/podcast/nick-smith-with-stagedive/id1637442450?i=1000641864874"
            imageUrl={require("../../assets/images/fearlessformula.png")}
            description="In this episode of Fearless Formula, Sharon Klein interviews Nick Smith, co-creator of StageDive, a new streaming platform for independent musicians and content creators. They discuss the difficulties artists face with current streaming services and how StageDive offers a fair and transparent payout system."
          />
        </div>
      </div>

      <div className="divider"></div>

      <div
        id="landing-social-feed"
        className="flex md:flex flex-wrap justify-around items-center p-5"
      >
        <div>
          <Tweet id="1750233726326571511" />
        </div>
        <div>
          <Tweet id="1752783733416132899" />
        </div>
        <div>
          <Tweet id="1749592518180978770" />
        </div>
      </div>
    </div>
  );
}
