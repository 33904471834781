import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserStore } from "store";
import placeholder from "../../assets/images/stagedive-logo.png";
import { SmallCardCarousel } from "../../components/MusicSections";
import { ApiContext } from "../../context";
import "./index.css";

export default function TrackView() {
  const stagedive = useContext(ApiContext);
  const { user } = useUserStore();
  const [isLoading, setIsLoading] = useState(true);
  const [similarTracksData, setSimilarTracksData] = useState([] as any[]);
  const [AlbumTracks, setAlbumTracks] = useState<Track[]>([]);
  const [Track, setTrack] = useState<Track>();
  const [creator, setCreator] = useState<string>("");
  const [trackName, setTrackName] = useState<string>("");
  const [coverImage, setcoverImage] = useState<string | null>("");

  //Uses params to pass in the album id
  const { id } = useParams();

  useEffect(() => {
    const getPageData = async (trackid) => {
      try {
        const track = await stagedive.getTrack(id);
        console.log(track);
        const creator = track.album.creators[0].name;
        const trackName = track.name;
        const coverImage = track.album.coverImageUrl;
        setCreator(creator);
        setTrackName(trackName);
        setcoverImage(coverImage);

        try {
          const album = await stagedive.getAlbum(track.album.id);
          setAlbumTracks(album.tracks);
        } catch (error) {
          console.error(error);
        }
        try {
          const similarTracks = await stagedive.getTracksByTags(
            track.tags[0].name,
            10,
            "-createdAt",
            0
          );
          setSimilarTracksData(similarTracks);
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (user) {
      getPageData(id);
      setIsLoading(false);
    }
  }, [id, user, stagedive]);

  return (
    <div className="track-view-container" id="container">
      {isLoading ? (
        <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
      ) : null}
      <div className="track-view-container left-side" id="track-container">
        <div className="track-heading">
          <h1 className="scroll-m-20 text-primary pb-2 text-3xl font-semibold tracking-tight first:mt-0">
            {trackName}
          </h1>
          <h2>{creator}</h2>
        </div>

        <img
          src={coverImage ?? placeholder}
          alt="Album Art"
          className="track-image bg-black"
        />
      </div>
      <div className="right-side">
        {/* TODO: Add commments component */}
        {/* <h2>Comments</h2> */}
        <SmallCardCarousel title="More from this Artist" tracks={AlbumTracks} />
        <SmallCardCarousel
          title="You might also like"
          tracks={similarTracksData}
        />
      </div>
    </div>
  );
}
