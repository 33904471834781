import { useErrorHandler } from "context/ErrorHandler";
import { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { ApiContext } from "../../context";

const TagSelector = ({ tags, onSelectChange }) => {
  const stagedive = useContext(ApiContext);
  const { handleError } = useErrorHandler();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    onSelectChange(tags);
  }, [tags]);

  const loadOptions = (inputValue, callback) => {
    setInputValue(inputValue);
    try {
      const count = 5;
      stagedive
        .searchTags(inputValue, count)
        .then((response) => {
          if (response.length > 0) {
            const tagOptions = response.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            callback(tagOptions);
          } else {
            callback([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          callback([]);
        });
    } catch (error) {
      handleError(error);
      callback([]);
    }
  };

  const handleChange = (selectedOptions) => {
    onSelectChange(selectedOptions);
  };

  return (
    <div>
      <p className="font-bold">Select Tags:</p>
      <AsyncSelect
        options={options}
        isMulti
        name="colors"
        className="basic-multi-select text-black"
        classNamePrefix="select"
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        onChange={handleChange}
        value={tags}
      />
    </div>
  );
};

export default TagSelector;
