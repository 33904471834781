import Hls from "hls.js";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import StageDiveAPI from "../api";

interface PlayerContextProps {
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  autoplayNext: boolean;
  setAutoplayNext: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentTime: number;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  isHLSInitialized: boolean;
  setIsHLSInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  canStream: boolean;
  setCanStream: React.Dispatch<React.SetStateAction<boolean>>;
  isLiked: boolean;
  setIsLiked: React.Dispatch<React.SetStateAction<boolean>>;
  playNewTrack: (track: Track) => Promise<void>;
  setLikeForTrack: (track: Track) => Promise<void>;
}

const PlayerContext = createContext<PlayerContextProps | undefined>(undefined);

export const PlayerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [autoplayNext, setAutoplayNext] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(() => {
    const storedTime = localStorage.getItem("currentTime");
    return storedTime ? parseFloat(storedTime) : 0;
  });
  const [duration, setDuration] = useState<number>(0);
  const [isHLSInitialized, setIsHLSInitialized] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0.5);
  const [canStream, setCanStream] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const stagedive = new StageDiveAPI();

  useEffect(() => {
    localStorage.setItem("currentTime", currentTime.toString());
  }, [currentTime]);

  const setLikeForTrack = useCallback(async (track: Track) => {
    try {
      const trackLikes = await stagedive.getTrackLikes(track.id);
      const liked = trackLikes.length === 1;
      setIsLiked(liked);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const playNewTrack = useCallback(
    async (track: Track) => {
      if (canStream) {
        // Pause current playback and reset video source
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
          videoRef.current.src = "";
        }

        setIsPlaying(false);
        setIsLoading(true);

        await setLikeForTrack(track);

        // Wait for the videoRef to be ready before setting source and playing
        if (videoRef.current && Hls.isSupported()) {
          const hls = new Hls({
            xhrSetup: (xhr, url) => {
              xhr.withCredentials = true;
            },
          });
          hls.loadSource(track.url);
          hls.attachMedia(videoRef.current);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            setIsHLSInitialized(true);
            setCurrentTime(0);
            setIsPlaying(true);
            setIsLoading(false);
            videoRef.current!.play().catch((error) => {
              console.error("Autoplay failed:", error);
            });
          });
        } else if (
          videoRef.current?.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = track.url;
          videoRef.current.onloadedmetadata = () => {
            setCurrentTime(0);
            setDuration(videoRef.current!.duration);
            setIsPlaying(true);
            setIsLoading(false);
            videoRef.current!.play().catch((error) => {
              console.error("Autoplay failed:", error);
            });
          };
        }
      }
    },
    [canStream, setLikeForTrack]
  );

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateTime = () => setCurrentTime(video.currentTime);
    const updateDuration = () => setDuration(video.duration);

    video.addEventListener("timeupdate", updateTime);
    video.addEventListener("durationchange", updateDuration);
    video.addEventListener("ended", () => setIsPlaying(false));

    return () => {
      video.removeEventListener("timeupdate", updateTime);
      video.removeEventListener("durationchange", updateDuration);
      video.removeEventListener("ended", () => setIsPlaying(false));
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);

  const contextValue = {
    isPlaying,
    setIsPlaying,
    autoplayNext,
    setAutoplayNext,
    isLoading,
    setIsLoading,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    isHLSInitialized,
    setIsHLSInitialized,
    volume,
    setVolume,
    videoRef,
    canStream,
    setCanStream,
    isLiked,
    setIsLiked,
    playNewTrack,
    setLikeForTrack,
  };

  return (
    <PlayerContext.Provider value={contextValue}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error("usePlayerContext must be used within a PlayerProvider");
  }
  return context;
};
