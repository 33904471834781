import {
  useGetNewReleases,
  useGetRandomTracks,
  useGetTracksByTags,
} from "hooks";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import {
  ListViewCarousel,
  SmallCardCarousel,
} from "../../components/MusicSections";
import "./Home.css";

const GenreCarousel = ({ title, tag }) => {
  const { data = [] } = useGetTracksByTags({
    tags: tag,
    sort: "random",
  });
  return <SmallCardCarousel title={title} tracks={data} />;
};

export default function Home() {
  const sessionContext = useSessionContext();
  const { data: newReleaseData = [] } = useGetNewReleases();
  const { data: exploreData = [] } = useGetRandomTracks();
  const { data: latestPodData = [] } = useGetTracksByTags({
    tags: "Podcast",
    sort: "-createdAt",
  });

  const genreCarousels = [
    { title: "Rap", tag: "Rap" },
    { title: "Rock", tag: "Rock" },
    { title: "Electronic", tag: "Electronica" },
    { title: "Experimental", tag: "Experimental" },
    { title: "Country", tag: "Country" },
    { title: "Folk", tag: "Folk" },
    { title: "Punk", tag: "Punk" },
    { title: "Indie", tag: "Indie" },
  ];

  if (sessionContext.loading === true) {
    return null;
  }

  return (
    <div className="fill" id="home-container bg-background">
      <div className="home-page">
        <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mt-4 text-primary">
          Welcome to StageDive
        </h1>

        <ListViewCarousel title="New Releases" tracks={newReleaseData} />
        <SmallCardCarousel title="Explore" tracks={exploreData} />
        <SmallCardCarousel title="Latest Podcasts" tracks={latestPodData} />
        {genreCarousels.map(({ title, tag }) => (
          <GenreCarousel key={tag} title={title} tag={tag} />
        ))}
      </div>
    </div>
  );
}
