import { useEffect } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { useUserStore } from "store";
import { CreatorRequests } from "./components/CreatorRequests";

const DashboardLayout = () => {
  const { user } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.roles.includes("admin")) navigate("/"); // Redirect if not admin
  }, [user, navigate]);

  return (
    <div className="p-6">
      <h1>Admin Dashboard</h1>
      <Outlet />
    </div>
  );
};

export const AdminDashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route path="creator-requests" element={<CreatorRequests />} />
      </Route>
    </Routes>
  );
};
