import {
  useGetFollowingLatestTracks,
  useGetMe,
  useGetUserLikedTracks,
  useGetUserPlaylists,
} from "hooks";
import React, { useEffect } from "react";
import { LibraryPlaylistCarousel } from "../../components/LibraryPlaylistCarousel";
import { ListViewCarousel } from "../../components/MusicSections";

export default function Library() {
  const { data: user } = useGetMe();
  const [isLoading, setIsLoading] = React.useState(true);

  const userId = user?.id;

  const { data: playlists = [], isLoading: playlistsLoading } =
    useGetUserPlaylists(userId);
  const { data: likedTracks = [], isLoading: likedTracksLoading } =
    useGetUserLikedTracks(userId);
  const {
    data: followingLatestTracks = [],
    isLoading: followingTracksLoading,
  } = useGetFollowingLatestTracks(userId);

  useEffect(() => {
    if (!playlistsLoading && !likedTracksLoading && !followingTracksLoading) {
      setIsLoading(false);
    }
  }, [playlistsLoading, likedTracksLoading, followingTracksLoading]);

  if (!user || isLoading) {
    return (
      <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
    );
  }

  return (
    <div className="p-10" id="profile-container">
      <h1 className="text-center">My Library</h1>
      <section>
        <ListViewCarousel
          title="Followed Creators"
          tracks={followingLatestTracks}
        />

        <ListViewCarousel title="Liked Tracks" tracks={likedTracks} />

        {playlists.map((playlist) => (
          <LibraryPlaylistCarousel
            key={playlist.id}
            playlistData={playlist}
            editable={true}
          />
        ))}
      </section>
    </div>
  );
}
