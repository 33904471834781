import { Skeleton } from "../../primitives";

export const SkeletonCardVertical = () => {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton className="w-32 h-32" />
      <div>
        <Skeleton className="w-32 h-5 mb-2" />
        <Skeleton className="w-32 h-3" />
      </div>
    </div>
  );
};

export const SkeletonCardHorizontal = () => {
  return (
    <div className="flex flex-col items-start w-max ">
      <div className="flex w-full gap-2 ">
        <Skeleton className="w-16 h-16" />
        <div>
          <Skeleton className="w-52 h-5 mb-2" />
          <Skeleton className="w-52 h-3" />
        </div>
      </div>
      <div className="flex w-full gap-2">
        <Skeleton className="w-16 h-16" />
        <div>
          <Skeleton className="w-52 h-5 mb-2" />
          <Skeleton className="w-52 h-3" />
        </div>
      </div>
      <div className="flex w-full gap-2">
        <Skeleton className="w-16 h-16" />
        <div>
          <Skeleton className="w-52 h-5 mb-2" />
          <Skeleton className="w-52 h-3" />
        </div>
      </div>
      <div className="flex w-full gap-2">
        <Skeleton className="w-16 h-16" />
        <div>
          <Skeleton className="w-52 h-5 mb-2" />
          <Skeleton className="w-52 h-3" />
        </div>
      </div>
    </div>
  );
};
