import { useCallback, useEffect } from "react";

export const useMediaSession = (
  handlePreviousSong,
  handleNextSong,
  currentSong,
  videoRef: React.RefObject<HTMLVideoElement>
) => {
  const updatePositionState = useCallback(() => {
    if (videoRef.current) {
      // Duration is NaN when HLS is not initialized
      const duration = Number.isNaN(videoRef.current.duration)
        ? 0
        : videoRef.current.duration;

      // Postion can't be greater than duration, and duration may be 0
      const position =
        videoRef.current.currentTime > duration
          ? 0
          : videoRef.current.currentTime;

      navigator.mediaSession.setPositionState({
        playbackRate: videoRef.current.playbackRate,
        duration,
        position,
      });
    }
  }, [videoRef]);

  useEffect(() => {
    if (navigator.mediaSession) {
      navigator.mediaSession.setActionHandler(
        "previoustrack",
        handlePreviousSong
      );
      navigator.mediaSession.setActionHandler("nexttrack", handleNextSong);
    }
  }, [handlePreviousSong, handleNextSong]);

  // Triggered when song changes
  useEffect(() => {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: currentSong.title,
      artist: currentSong.artist,
      album: currentSong.albumName,
      artwork: [{ src: currentSong.albumArt }],
    });
    updatePositionState();
  }, [currentSong, updatePositionState]);

  return { updatePositionState };
};
