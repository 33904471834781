import { PlayIcon } from "@radix-ui/react-icons";
import { cva, type VariantProps } from "class-variance-authority";
import { usePlaylistPlayer } from "context/playlistPlayer";
import React from "react";
import placeholder from "../../assets/images/stagedive-logo.png";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../primitives";
import { cn } from "../../utils";
import AdaptiveSongTitle from "../AdaptiveSongTitle";
import "./MusicCard.css";

interface MusicCardProps
  extends React.ComponentProps<"div">,
    VariantProps<typeof musicCardVariants> {
  track: Track;
  index: number;
  trackArray: Track[];
}

const musicCardVariants = cva(
  "group flex  cursor-pointer overflow-hidden border-0 mr-2 rounded",
  {
    variants: {
      variant: {
        vertical: "h-16 gap-2",
        horizontal: "w-32 flex-col",
      },
    },
    defaultVariants: {
      variant: "horizontal",
    },
  }
);

const musicCardImageVariants = cva(
  "rounded overflow-hidden p-0 flex items-center justify-center w-full h-full",
  {
    variants: {
      variant: {
        vertical: "w-16 h-16",
        horizontal: "w-32 h-32",
      },
    },
    defaultVariants: {
      variant: "horizontal",
    },
  }
);

const AdaptiveMusicCard: React.FC<MusicCardProps> = ({
  track,
  className,
  variant,
  trackArray,
  index,
}) => {
  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();

  const onClickHandler = () => {
    clearAndLoadNewPlaylist(trackArray, index);
  };
  return (
    <Card
      onClick={onClickHandler}
      className={cn(musicCardVariants({ className, variant }), "")}
    >
      <CardHeader
        className={cn(musicCardImageVariants({ variant }), "flex-shrink-0")}
      >
        <img
          src={track.album?.coverImageUrl ?? placeholder}
          alt="Album Art"
          className="object-cover group-hover:opacity-25 "
        />
        <PlayIcon
          className={cn(
            "opacity-0 absolute group-hover:bg-secondary/85 group-hover:opacity-100 rounded-full p-2",
            variant === "horizontal" ? "w-12 h-12" : "w-8 h-8"
          )}
        />
      </CardHeader>
      <CardContent className="p-0 mt-2 ">
        <CardTitle>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild className="truncate leading-tight">
                <p className="truncate ">{track.name} </p>
              </TooltipTrigger>
              <TooltipContent>
                <p>{track.name}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </CardTitle>
        <CardDescription className="inline-block mt-1 h-12">
          <AdaptiveSongTitle track={track} />
        </CardDescription>
      </CardContent>
    </Card>
  );
};

export default AdaptiveMusicCard;
