import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../utils";

const linkVariants = cva("block", {
  variants: {
    variant: {
      normal: "",
      bold: "",
    },
  },
});

interface LinkProps
  extends React.ComponentProps<"a">,
    VariantProps<typeof linkVariants> {}

const Link = ({ className, children, href, ...props }: LinkProps) => {
  return (
    <a className={cn(linkVariants({ className }))} href={href} {...props}>
      {children}
    </a>
  );
};

export { Link };
