import { useQueryClient } from "@tanstack/react-query";
import { useErrorHandler } from "context/ErrorHandler";
import { useContext, useEffect, useState } from "react";
import { useUserStore } from "store";
import { ApiContext } from "../../context";
import Modal from "../Modal";
import "./index.css";

type PlaylistModalProps = {
  open;
  handleToggle;
  track;
};

const PlaylistModal = ({ open, handleToggle, track }: PlaylistModalProps) => {
  const queryClient = useQueryClient();
  const stagedive = useContext(ApiContext);
  const { user } = useUserStore();
  const { handleError } = useErrorHandler();

  const [playlists, setPlaylists] = useState([] as any); // TODO create Playlist type and set to Playlist[]
  const [showCreatePlaylist, setShowCreatePlaylist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPlaylists = async () => {
      if (user) {
        setIsLoading(true);
        try {
          const playlists = await stagedive.getUserPlaylists(user.id);
          setPlaylists(playlists);
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      }
    };
    getPlaylists();
  }, [stagedive, user]);

  const toggleCreatePlaylist = () => {
    setShowCreatePlaylist(!showCreatePlaylist);
  };

  const createPlaylist = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!user) {
      return;
    }

    try {
      const playlist = {
        name: e.target.name.value.trim(),
        userId: user.id,
        trackIds: [],
      };

      if (playlist.name === "") {
        throw new Error("Playlist name is empty");
      }

      const newPlaylist = await stagedive.createPlaylist(playlist);
      setPlaylists([...playlists, newPlaylist]);
      queryClient.invalidateQueries({
        queryKey: ["getUserPlaylists", user.id],
      });
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
    toggleCreatePlaylist();
  };

  const updatePlaylist = async (playlist, currentTrack) => {
    setIsLoading(true);

    if (!user) return;

    try {
      const currentPlaylistTrackIds = playlist.tracks.map((track) => track.id);

      let updatedTrackList = [] as any;

      if (currentPlaylistTrackIds.includes(currentTrack.id)) {
        // remove track, since already in the playlist
        updatedTrackList = currentPlaylistTrackIds.filter(
          (trackId) => trackId !== currentTrack.id
        );
      } else {
        // else add track
        updatedTrackList = [...currentPlaylistTrackIds, currentTrack.id];
      }

      const playlistDTO = {
        trackIds: updatedTrackList,
      };

      const updatedPlaylist = await stagedive.updatePlaylist(
        playlist.id,
        playlistDTO
      );

      const updatedPlaylists = playlists.map((playlist: any) => {
        if (playlist.id === updatedPlaylist.id) {
          return updatedPlaylist;
        } else {
          return playlist;
        }
      });

      setPlaylists(updatedPlaylists);
      queryClient.invalidateQueries({
        queryKey: ["getUserPlaylists", user.id],
      });
    } catch (err) {
      handleError(err);
    }

    setIsLoading(false);
  };

  const playlistContainsTrack = (playlist, track) => {
    if (!track) return false;
    return playlist.tracks.some(
      (playlistTrack) => playlistTrack.id === track.id
    );
  };

  return (
    <Modal open={open} onClose={handleToggle}>
      <h3 className="font-bold text-lg">Add track to...</h3>
      <div className="h-[400px] overflow-y-scroll no-scrollbar">
        {playlists.length > 0 ? (
          <ul className="menu menu-vertical w-100">
            {playlists.map((playlist) => (
              <li
                key={playlist.id}
                className={`${
                  playlistContainsTrack(playlist, track)
                    ? "bg-neutral-500"
                    : "bg-neutral-900"
                } mb-3 p-3 transition duration-300 ease-in-out hover:bg-neutral-500 ${
                  playlistContainsTrack(playlist, track) && "bg-neutral-500"
                }`}
                onClick={() => updatePlaylist(playlist, track)}
              >
                <div>
                  <span className="text-base mr-1">+</span>
                  {playlist.name}
                  <p className="ml-auto">{`${playlist.tracks.length} tracks`}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No playlists created</p>
        )}
      </div>

      {showCreatePlaylist ? (
        <CreatePlaylistForm
          toggleForm={toggleCreatePlaylist}
          handleSubmit={createPlaylist}
          isLoading={isLoading}
        />
      ) : (
        <div className="modal-action justify-start">
          <button
            className="btn normal-case transition duration-300 ease-in-out hover:bg-neutral-700"
            onClick={toggleCreatePlaylist}
          >
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              "+ New Playlist"
            )}
          </button>
        </div>
      )}
    </Modal>
  );
};

type CreatePlaylistFormProps = {
  toggleForm;
  handleSubmit;
  isLoading;
};

const CreatePlaylistForm = ({
  toggleForm,
  handleSubmit,
  isLoading,
}: CreatePlaylistFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="join">
        <div className="join-item">
          <label className="label" htmlFor="name">
            <span className="label-text">Playlist Name</span>
          </label>
          <input type="text" name="name" className="input bg-white w-48" />
        </div>
        <div className="ml-3 mt-9 flex flex-wrap">
          <button
            className="btn normal-case mr-3 mb-1"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              "Save"
            )}
          </button>
          <button
            className="btn normal-case"
            onClick={toggleForm}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              "Back"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default PlaylistModal;
