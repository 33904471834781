import { useQuery } from "@tanstack/react-query";

import StageDiveAPI from "../api";
import { useUserStore } from "../store";

const stagedive = new StageDiveAPI();

const defaultStaleTime = 1000 * 60 * 5;

export const useGetNewReleases = () => {
  const count = 100;

  const extractDistinctTracksFromAlbums = (tracks: Track[]): Track[] => {
    const albumTrackMap: { [key: number]: Track } = {};

    tracks.forEach((track) => {
      const albumId = track.album.id;
      if (!albumTrackMap[albumId]) {
        albumTrackMap[albumId] = track;
      }
    });

    return Object.values(albumTrackMap);
  };

  return useQuery({
    queryKey: ["getLatestTracks", count],
    queryFn: () => stagedive.getLatestTracks(count),
    select: (data) => extractDistinctTracksFromAlbums(data),
    staleTime: defaultStaleTime,
  });
};

export const useGetRandomTracks = () => {
  return useQuery({
    queryKey: ["getRandomTracks"],
    queryFn: () => stagedive.getRandomTracks(),
    staleTime: defaultStaleTime,
  });
};

export const useGetTracksByTags = ({
  tags,
  count = 20,
  offset = 0,
  sort = "random",
}: {
  tags: string;
  count?: number;
  offset?: number;
  sort?: string;
}) => {
  return useQuery({
    queryKey: ["getTracksByTags", tags, count, sort, offset],
    queryFn: () => stagedive.getTracksByTags(tags, count, sort, offset),
    staleTime: defaultStaleTime,
  });
};

export const useGetMe = () => {
  const { setUser } = useUserStore();
  return useQuery({
    queryKey: ["getMe"],
    queryFn: () =>
      stagedive.me().then((user) => {
        setUser(user);
        return user;
      }),
    staleTime: defaultStaleTime,
  });
};

export const useGetUserPlaylists = (userId) => {
  return useQuery({
    queryKey: ["getUserPlaylists", userId],
    queryFn: () => stagedive.getUserPlaylists(userId),
    staleTime: defaultStaleTime,
  });
};

export const useGetUserLikedTracks = (userId) => {
  return useQuery({
    queryKey: ["getUserLikedTracks", userId],
    queryFn: () => stagedive.getUserLikedTracks(userId),
    staleTime: defaultStaleTime,
  });
};

export const useGetFollowingLatestTracks = (userId) => {
  return useQuery({
    queryKey: ["getFollowingLatestTracks", userId],
    queryFn: () => stagedive.getFollowingLatestTracks(userId),
    staleTime: defaultStaleTime,
  });
};
