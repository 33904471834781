import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";

export function getApiDomain() {
  const apiUrl = process.env.REACT_APP_STAGEDIVE_API_URL || "";
  return apiUrl;
}

export function getWebsiteDomain() {
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL || "";
  return websiteUrl;
}

export const SuperTokensConfig = {
  appInfo: {
    appName: "StageDive",
    apiDomain: getApiDomain(),
    websiteDomain: getWebsiteDomain(),
    apiBasePath: "/v1/auth",
    websiteBasePath: "/auth",
  },
  getRedirectionURL: async (context) => {
    if (context.action === "SUCCESS" && context.newSessionCreated) {
      let redirectToPath = context.redirectToPath;
      if (context.createdNewUser) {
        // on register, send to the buy now page
        return "/signup/checkout";
      } else if (redirectToPath !== undefined) {
        // redirecting to the path in ?redirectToPath query param
        return redirectToPath;
      } else {
        // on login, send to home page
        return "/";
      }
    }

    // return undefined to let the default behaviour play out
    return undefined;
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        providers: [
          // Github.init(),
          // Google.init(),
          // Apple.init(),
          // Facebook.init(),
        ],
        signUpForm: {
          termsOfServiceLink: "https://legal.stagedive.com/tos.html",
        },
      },
      style: `
                [data-supertokens~=container] {
                    max-width: 100%;
                    --palette-background: 51, 51, 51;
                    --palette-inputBackground: 41, 41, 41;
                    --palette-inputBorder: 41, 41, 41;
                    --palette-textTitle: 255, 255, 255;
                    --palette-textLabel: 255, 255, 255;
                    --palette-textPrimary: 255, 255, 255;
                    --palette-error: 173, 46, 46;
                    --palette-textInput: 169, 169, 169;
                    --palette-textLink: 169, 169, 169;
                }

                [data-supertokens~="link"]  {
                  color: var(--blue);
                }

                [data-supertokens~="button"] {
                  background-color: var(--yellow);
                  color: black;
                  border-color: transparent;
                  font-family: var(--font-highlight);
                }

                @media (max-width: 601px) {
                  [data-supertokens~="input"] {
                    font-size: 16px;
                  }
                } 
            `,
    }),
    EmailVerification.init({
      mode: "OPTIONAL",
    }),
    Session.init(),
  ],
};

export const recipeDetails = {
  docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const PreBuiltUIList = [
  ThirdPartyEmailPasswordPreBuiltUI,
  EmailVerificationPreBuiltUI,
];

export const newRelicOptions =
  process.env.REACT_APP_ENV === "dev"
    ? {
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ["bam.nr-data.net"] },
        },
        info: {
          beacon: "bam.nr-data.net",
          errorBeacon: "bam.nr-data.net",
          licenseKey: "NRJS-5fe8a6d384c6b175fe7",
          applicationID: "1588863345",
          sa: 1,
        },
        loader_config: {
          accountID: "3946586",
          trustKey: "3946586",
          agentID: "1588863345",
          licenseKey: "NRJS-5fe8a6d384c6b175fe7",
          applicationID: "1588863345",
        },
      }
    : {
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ["bam.nr-data.net"] },
        },
        info: {
          beacon: "bam.nr-data.net",
          errorBeacon: "bam.nr-data.net",
          licenseKey: "NRJS-5fe8a6d384c6b175fe7",
          applicationID: "1588905916",
          sa: 1,
        },
        loader_config: {
          accountID: "3946586",
          trustKey: "3946586",
          agentID: "1588905916",
          licenseKey: "NRJS-5fe8a6d384c6b175fe7",
          applicationID: "1588905916",
        },
      };
