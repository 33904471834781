// usePlaylistPlayer.ts
import Hls from "hls.js";
import { useCallback } from "react";
import { usePlaylistContext } from "./NewPlaylistContext";
import { usePlayerContext } from "./PlayerContext";

export const usePlaylistPlayer = () => {
  const { setPlaylistData, setCurrentSongIndex } = usePlaylistContext();

  const {
    videoRef,
    canStream,
    setIsPlaying,
    setAutoplayNext,
    setDuration,
    setCurrentTime,
    setIsLoading,
    setLikeForTrack,
  } = usePlayerContext();

  const clearAndLoadNewPlaylist = useCallback(
    async (tracks: Track[], index: number) => {
      if (!canStream) return;

      // Pause current playback and reset video source
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current.src = "";
      }
      setIsPlaying(false);

      // Clear the current playlist
      setPlaylistData(tracks);
      setCurrentSongIndex(index);
      setAutoplayNext(true);
      setDuration(0);

      try {
        // Update local storage
        localStorage.setItem("playlistData", JSON.stringify(tracks));
        localStorage.setItem("currentSongIndex", index.toString());

        // Wait for the videoRef to be ready before setting source and playing
        if (videoRef.current && Hls.isSupported()) {
          const hls = new Hls({
            xhrSetup: (xhr, url) => {
              xhr.withCredentials = true;
            },
          });
          hls.loadSource(tracks[index].url);
          hls.attachMedia(videoRef.current);
          await setLikeForTrack(tracks[index]);

          videoRef.current.onloadedmetadata = () => {
            setCurrentTime(0);
            setDuration(videoRef.current!.duration);
            setIsPlaying(true); // Start playback
          };
        } else if (
          videoRef.current?.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = tracks[index].url;
          setIsLoading(false);

          videoRef.current.onloadedmetadata = () => {
            setCurrentTime(0);
            setDuration(videoRef.current!.duration);
            setIsPlaying(true); // Start playback
          };
        }
      } catch (error) {
        console.error((error as Error).message);
      }
    },
    [
      canStream,
      videoRef,
      setIsPlaying,
      setPlaylistData,
      setCurrentSongIndex,
      setAutoplayNext,
      setDuration,
      setCurrentTime,
      setIsLoading,
      setLikeForTrack,
    ]
  );

  return { clearAndLoadNewPlaylist };
};
