import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

type Props = {
  children: React.ReactNode;
  parentClass?: string;
  backgroundColor?: string;
  open: boolean;
  onClose(): void;
};

const Modal = ({
  children,
  open,
  onClose,
  parentClass = "",
  backgroundColor = "bg-zinc-800",
}: Props) => {
  const modalRef = useRef<any>(null);
  const isOpen = open ? `modal-open` : "";

  useOnClickOutside(modalRef, () => {
    if (open) onClose();
  });

  return (
    <div className={`modal modal-middle ${isOpen} ${parentClass}`}>
      <div className={`modal-box ${backgroundColor}`} ref={modalRef}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
