import { useEffect, useState } from "react";

import "./index.css";

const LikeButton = ({ liked: initialLiked, onClick }) => {
  const [liked, setLiked] = useState(initialLiked);
  const [bounce, setBounce] = useState(false);

  useEffect(() => {
    setLiked(initialLiked);
  }, [initialLiked]);

  const handleClick = () => {
    setLiked(!liked);
    setBounce(true);
    setTimeout(() => {
      setBounce(false);
    }, 500);
    onClick && onClick(!liked);
  };

  return (
    <button
      className={`
        bg-transparent border-none text-2xl cursor-pointer 
        ${liked ? "filter-none" : "grayscale"}
        ${bounce ? "animate-bounce-once" : "hover:animate-pulse"}
      `}
      onClick={handleClick}
    >
      <span role="img" aria-label="Like" title="Like">
        🔥
      </span>
    </button>
  );
};

LikeButton.defaultProps = {
  liked: false,
  onClick: null,
};

export default LikeButton;
