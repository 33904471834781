import { Link, Outlet, Route, Routes } from "react-router-dom";
import { useUserStore } from "store";
import CreatorDashboard from "./components/CreatorDashboard";
import ProfileInfo from "./components/ProfileInfo";
import ProfileSubscription from "./components/ProfileSubscription";
import "./Profile.css";

const ProfileLayout = () => {
  const { user } = useUserStore();
  const isCreator = (user) => {
    if (!user.roles) return false;
    else return user.roles.includes("creator");
  };

  if (!user) return null;

  return (
    <div id="profile-page">
      <div className={`drawer md:drawer-open`}>
        <input
          id="drawer-toggle"
          className="drawer-toggle"
          type="checkbox"
          defaultChecked
        />

        <div className="drawer-content">
          {/* Page content here */}
          <label
            htmlFor="drawer-toggle"
            className="drawer-button fixed md:hidden"
          >
            <span className="material-symbols-rounded">arrow_back</span>
          </label>
          <Outlet />
        </div>

        <div className="drawer-side top-[70px]">
          <label
            htmlFor="drawer-toggle"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul
            onClick={() => $("#drawer-toggle").trigger("click")}
            className="menu p-4 min-h-full w-3/4 md:w-auto bg-base-200 text-base-content"
          >
            {/* Sidebar content here */}
            <li>
              <Link to={`/user/${user.id}`}>Profile</Link>
            </li>
            <li>
              <Link to="/profile">Account</Link>
            </li>
            <li>
              <Link to="subscriptions">Subscriptions</Link>
            </li>

            {isCreator(user) ? (
              <li>
                <Link to="creator-dashboard">Creator Dashboard</Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default function Profile() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ProfileLayout />}>
          <Route index element={<ProfileInfo />} />
          <Route path="subscriptions" element={<ProfileSubscription />} />
          <Route path="creator-dashboard" element={<CreatorDashboard />} />
        </Route>
      </Routes>
    </div>
  );
}
