import React, { createContext, useContext, useEffect, useState } from "react";

interface PlaylistContextProps {
  playlistData: Track[];
  setPlaylistData: React.Dispatch<React.SetStateAction<Track[]>>;
  currentSongIndex: number;
  setCurrentSongIndex: React.Dispatch<React.SetStateAction<number>>;
}

const PlaylistContext = createContext<PlaylistContextProps | undefined>(
  undefined
);

export const PlaylistProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [playlistData, setPlaylistData] = useState<Track[]>(() => {
    const storedData = localStorage.getItem("playlistData");
    return storedData ? JSON.parse(storedData) : [];
  });

  const [currentSongIndex, setCurrentSongIndex] = useState<number>(() => {
    const storedIndex = localStorage.getItem("currentSongIndex");
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem("playlistData", JSON.stringify(playlistData));
  }, [playlistData]);

  useEffect(() => {
    localStorage.setItem("currentSongIndex", currentSongIndex.toString());
  }, [currentSongIndex]);

  return (
    <PlaylistContext.Provider
      value={{
        playlistData,
        setPlaylistData,
        currentSongIndex,
        setCurrentSongIndex,
      }}
    >
      {children}
    </PlaylistContext.Provider>
  );
};

export const usePlaylistContext = () => {
  const context = useContext(PlaylistContext);
  if (!context) {
    throw new Error(
      "usePlaylistContext must be used within a PlaylistProvider"
    );
  }
  return context;
};
